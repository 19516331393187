import react, { useEffect, useState } from "react";

import './MobileHistoryTile.css';

const MobileHistoryTile = ({fundName, data}) => {
    let [isExpanded, setIsExpanded] = useState(false);

    let handleExpandToggle = () => {
        if(isExpanded){
            setIsExpanded(false);
        }else if(!isExpanded){
            setIsExpanded(true);
        }
    }

    // Reverse data to get the most recent entry on top when rendering
    data = data.slice().reverse()

    return(
        <>
        <div className="mobile-history-tile-container">
            <div className="mobile-history-tile-expand-header-container">
                <div className="mobile-history-tile-expand-header">
                    <p>{fundName}</p>
                </div>
                <div className="mobile-history-tile-expand-close">
                    <p onClick={handleExpandToggle}>Visa mer</p>
                </div>
            </div>
                <div className="mobile-history-tile-table-container">
                    <table className="mobile-history-tile-table">
                        <thead>
                            <tr className="mobile-history-tile-table-header">
                                <th className="mobile-history-table-th align-left">Datum</th>
                                <th className="mobile-history-table-th align-right">Prognos</th>
                                {/*<th className="history-table-th align-right">Utfall</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(0,3).map(function(data, i){ //Loop thorugh the first 3 instances in the data array
                                // Set colorClass
                                let colorClass="history-chg-pos";
                                if(data["pct_chg_prediction"] < 0){
                                    colorClass="history-chg-neg";
                                }

                                // Render table row with values and colorClass
                                return(
                                    <tr key={i}>
                                        <td className="mobile-history-table-td align-left">{data["time_stamp"]}</td>
                                        <td className={"mobile-history-table-td align-right " + colorClass}>{data["pct_chg_prediction"].toFixed(2)}%</td>
                                        {/*<td className="history-table-td align-right history-chg-pos">0.98%</td>*/}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {isExpanded && (
                <div className="mobile-history-tile-expanded-backdrop">
                    <div className="mobile-history-tile-expanded-container">
                        <div className="mobile-history-tile-expand-header-container">
                            <div className="mobile-history-tile-expand-header">
                                <p>{fundName}</p>
                            </div>
                            <div className="mobile-history-tile-expand-close">
                                <p onClick={handleExpandToggle}>Stäng</p>
                            </div>
                        </div>
                        <div className="mobile-history-tile-expand-table-container">
                            <table className="mobile-history-tile-table-expand">
                                <thead>
                                    <tr className="mobile-history-tile-table-header">
                                        <th className="mobile-history-table-th align-left">Datum</th>
                                        <th className="mobile-history-table-th align-right">Prognos</th>
                                        {/*<th className="history-table-th align-right">Utfall</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(function(data, i){ //Loop thorugh all instances in the data array
                                        // Set colorClass
                                        let colorClass="history-chg-pos";
                                        if(data["pct_chg_prediction"] < 0){
                                            colorClass="history-chg-neg";
                                        }

                                        // Render table row with values and colorClass
                                        return(
                                            <tr key={i}>
                                                <td className="mobile-history-table-td align-left">{data["time_stamp"]}</td>
                                                <td className={"mobile-history-table-td align-right " + colorClass}>{data["pct_chg_prediction"].toFixed(2)}%</td>
                                                {/*<td className="history-table-td align-right history-chg-pos">0.98%</td>*/}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            )}
        </>
        
    );
};

export default MobileHistoryTile;