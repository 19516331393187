import React from "react";

import './DashFooter.css'

export default function DashFooter(props){
    var {data} = props;

    var update_timestamp = new Date(data.index.at(-1)).toISOString();
    var update_date = update_timestamp.substring(0, 10);
    var update_time = update_timestamp.substring(11, 19);
    var update_year = update_timestamp.substring(0, 4);

    const [time, setTime] = React.useState();

    React.useEffect(() => {
        const timer = setInterval(() => {
        setTime(new Date().toLocaleString());
        }, 1000);

        return () => {
        clearInterval(timer);
        };
    }, []);


    return (
        <>
            <div className="footer">
                <div className="footer-section">
                    <div className="footer-item">
                        <p>Data från {update_date} {update_time}</p>
                    </div>
                </div>
                <div className="footer-section">
                    <div className="footer-item">
                        <p>© Miax Development / Bunkern {update_year}</p>
                    </div>
                </div>
                <div className="footer-section">
                    <div className="footer-item">
                        <p>{time}</p>
                    </div>
                </div>
            </div>
        </>
    );

};