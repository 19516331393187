import React, { useEffect } from "react";


import './FundTable.css'

export default function FundTable (props) {
    const {fundData} = props;

    // Test to get sortable table 

    // Structure initial table data
    let [tableData, setTableData] = React.useState([])
    let [sortedTableData, setSortedTableData] = React.useState([])
    let [sortedField, setSortedField] = React.useState('Return today');
    let [sortDirection, setSortDirection] = React.useState("des");

    const parseTableData = () => {
        // Check if function alreade has been run e.g. if tableData != []
        if (tableData.length === 0){
            for(let i = 0; i < Object.keys(fundData.FX).length; i++){
            let tableDataItem = {
                'Ticker' : fundData["ISIN"][i],
                'Asset' : fundData["Asset"][i],
                'FX' : fundData["FX"][i],
                'Return today' : fundData["Return today (%)"][i],
                'Total return' : fundData["Return total (%)"][i],
                'Weight' : fundData["Position weight (%)"][i],
            }
            //console.log(tableDataItem)
            tableData.push(tableDataItem)

            //setTableData(tableData => [...tableData, tableDataItem])
            }
        }
    }

      // On page load, parse the table data
      useEffect(() => {
        parseTableData()
    }, [])
    

    let compare = (a, b) =>{
        if(a[sortedField] < b[sortedField]){
            return -1;
        }
        if (a[sortedField] < b[sortedField]){
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        let tempTableCopy = tableData 
        let tempSortedTabelData = []
        

        if(sortDirection === "asc"){
            tempSortedTabelData = tempTableCopy.sort(compare)
        }else{
            tempSortedTabelData = tempTableCopy.sort(compare).reverse()
        }

        let tempTableRows = []

        // Generate table data rows to be displayed in table
        for(let i = 0; i < Object.keys(tempSortedTabelData).length; i++){
            //console.log(tempSortedTabelData[i]["Asset"]);

            // Set color class for values
            let retTodayColorClass = 'pct-chg-pos';
            if(tempSortedTabelData[i]["Return today"] < 0){
                retTodayColorClass = "pct-chg-neg";
            }

            let retSinceBuyColorClass = "pct-chg-pos";
            if(tempSortedTabelData[i]["Total return"] < 0){
                retSinceBuyColorClass = "pct-chg-neg";
            }

            let weightColorClass = "pct-chg-pos";
            if(tempSortedTabelData[i]["Weight"] < 0){
                weightColorClass = "pct-chg-neg";
            }

            // Set flag URL for currency
            let flagUrl = "https://hatscripts.github.io/circle-flags/flags/se.svg";
            if(tempSortedTabelData[i]["FX"] === "USD"){
                flagUrl = "https://hatscripts.github.io/circle-flags/flags/us.svg";
            }else if(tempSortedTabelData[i]["FX"] === "EUR"){
                flagUrl = "https://hatscripts.github.io/circle-flags/flags/eu.svg";
            }else if(tempSortedTabelData[i]["FX"] === "DKK"){
                flagUrl = "https://hatscripts.github.io/circle-flags/flags/dk.svg";
            }

            //console.log(i)
            let table_row = (
                <tr className="fund-tr" key={tempSortedTabelData[i]["Ticker"] + " FundTable"}>
                    <td className="fund-td">
                        <img src={flagUrl} width="15" alt="flag" />
                    </td>
                    <td className="fund-td">
                        {tempSortedTabelData[i]["Asset"]}
                    </td>
                    <td className={"fund-td align-right " + retTodayColorClass}>
                        {tempSortedTabelData[i]["Return today"]}%
                    </td>
                    <td className={"fund-td align-right " + retSinceBuyColorClass}>
                        {tempSortedTabelData[i]["Total return"]}%
                    </td>
                    <td className={"fund-td align-right " + weightColorClass}>
                        {tempSortedTabelData[i]["Weight"]}%
                    </td>
                </tr>
            )
            tempTableRows.push(table_row)
        }

        setSortedTableData(tempTableRows)
        setSortDirection("des")

    }, [sortedField, sortDirection])

    const sortTable = (sortColumn) => {
        if(sortColumn === sortedField){
            //console.log("SAMMA KOLUMN VALD")
            //setSortDirection("asc") // Keep this uncommented until isse has been resolved
        }else{
            setSortedField(sortColumn)
        }
    }

    return (
        <>
            <div className="fund-table-container">
                <table className="fund-table">
                    <thead>
                        <tr>
                            <th className="fund-th"></th>
                            <th className="fund-th"></th>
                            <th className="fund-th align-right" onClick={() => sortTable("Return today")}>Idag</th>
                            <th className="fund-th align-right" onClick={() => sortTable("Total return")}>Totalt</th>
                            <th className="fund-th align-right"onClick={() => sortTable("Weight")}>Vikt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedTableData}
                    </tbody>
                </table>
            </div>
        </>
    )

}