import React, {useRef, useEffect, useState} from "react";
import './DashData.css';

const DashData = ({id, data})  => {

    // Get height of eacht data-table element for calculating number of table rows to show
    const elRef = useRef();
    const [height, setHeight] = useState(0);
    let [tableRowsCount, setTableRowsCount] = useState(3); // Keep track of how many rows can fit
    useEffect(() => {
        if (!elRef?.current?.clientHeight) {
            return;
        }
        setHeight(elRef?.current?.clientHeight);

        // Calculate how many table rows can fit depending on the height
        // Space for table (height - space for header - space for bottom margin)
        let spaceForTable = height - 26 - 10
        // Calculate how many table rows fit in the available space
        let calculatedTableRows = (spaceForTable / 40).toFixed(0)
        // Set the amount of rows, if over three
        if(calculatedTableRows > 3){
            setTableRowsCount(calculatedTableRows);
        };
    }, [elRef?.current?.clientHeight]);

    // Table data generation
    let table_rows_pos = [];
    function pos_table_gen(data) {
        for (let i = 0; i < tableRowsCount; i++) {
            
            // Set the correct flag URL depending on FX and company name for Finland, (Special case :))
            var src_flag = "https://hatscripts.github.io/circle-flags/flags/se.svg";
            if(data.Asset[i].toLowerCase().includes("oyj")){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/fi.svg";
            }else if(data.FX[i] === "USD"){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/us.svg";
            }else if(data.FX[i] === "EUR"){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/eu.svg";
            }else if(data.FX[i] === "DKK"){
            src_flag = "https://hatscripts.github.io/circle-flags/flags/dk.svg";
            }

            // Set the correct color depending on positive or negative yield
            var pct_chg_val = ""//parseFloat(data["Return today (%)"][i]);
            var pct_chg_class = "pct_neg";
            if(pct_chg_val >= 0){
                pct_chg_class = "pct_pos";
            }
        
            // Generate a table row for the asset passed in [i]
            var table_row =  (
                <tr className="data-tr" key={data.Asset[i]}>
                    <td className="col-flag data-td">
                        <img src={src_flag} width="15" alt="flag" />
                    </td>
                    <td className="col-asset data-td">
                        {data.Asset[i]}
                    </td>
                    <td className="col-return data-td">
                        <p className={pct_chg_class}>{Number(data["Return today (%)"][i]).toFixed(2)}%</p>
                    </td>
                </tr>
            )
            // The generated table row is stored in an array
            table_rows_pos.push(table_row)
        }
        return table_rows_pos;
    };

    let table_rows_neg = []
    function neg_table_gen(data) {
        // Get the length of the array
        var am_assets = Object.keys(data.Asset).length;
        
        // Run through the list from the back
        for (let y = am_assets-1; y > am_assets-tableRowsCount-1; y--) {
            
            // Set the correct flag URL depending on FX
            var src_flag = "https://hatscripts.github.io/circle-flags/flags/se.svg";
            if(data.Asset[y].toLowerCase().includes("oyj")){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/fi.svg";
            }else if(data.FX[y] === "USD"){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/us.svg";
            }else if(data.FX[y] === "EUR"){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/eu.svg";
            }else if(data.FX[y] === "DKK"){
            src_flag = "https://hatscripts.github.io/circle-flags/flags/dk.svg";
            }
            // Set the correct color depending on positive or negative yield
            var pct_chg_val = parseFloat(data["Return today (%)"][y]);
            
            var pct_chg_class = "pct_neg";
            if(pct_chg_val >= 0){
                pct_chg_class = "pct_pos";
            }
        
            // Generate a table row for the asset passed in [i]
            var table_row = (
                <tr className="data-tr" key={data.Asset[y]}>
                    <td className="col-flag data-td">
                        <img src={src_flag} width="15" alt="flag" />
                    </td>
                    <td className="col-asset data-td">
                        {data.Asset[y]}
                    </td>
                    <td className="col-return data-td">
                        <p className={pct_chg_class}>{Number(data["Return today (%)"][y]).toFixed(2)}%</p>
                    </td>
                </tr>
            )
            // The generated table row is stored in an array
            table_rows_neg.push(table_row)
        }
        return table_rows_neg;
    };

    return(
        <div className="dash-data-container" id={id}>
            <div className="dash-data-section" ref={elRef}>
                <p className="info-text-heading">Dagens vinnare</p>
                <table className="data-table">
                    <tbody>
                        {pos_table_gen(data)}
                    </tbody>
                </table>
            </div>
            <div className="dash-data-section">
                <p className="info-text-heading info-text-heading-second-margin">Dagens förlorare</p>
                <table className="data-table">
                    <tbody>
                        {neg_table_gen(data)}
                    </tbody>
                </table>
            </div>
        </div> 
    )
}

export default DashData;