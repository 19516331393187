// https://medium.com/@ronakchitlangya1997/jwt-authentication-with-react-js-and-django-c034aae1e60d

import axios from "axios";
//import { NavLink } from "react-router-dom";

axios.defaults.baseURL = 'https://sk-fv.azurewebsites.net'; //'http://192.168.1.130:8000'; //'http://localhost:8000" //"https://sk-fv.azurewebsites.net"

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;

        //console.log(localStorage.getItem('refresh_token'))
        const response = await axios.post('/token/refresh/', {
            refresh:localStorage.getItem('refresh_token')
        }, {
            headers: {
              'Content-Type': 'application/json',
            }
          },{withCredentials: true});

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            console.log("Token refresh") // New access_token: " + localStorage.getItem('access_token'))

            error.config.headers['Authorization'] = `Bearer ${response.data['access']}`; // This is to use teh new accesstoken in the new request
            return axios(error.config);

        }else{
            window.location = "/login"
        }
    }
    refresh = false;
    return error;    
});