import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { NavLink } from "react-router-dom";
import './MobileDashFundTile.css';

const MobileDashFundTile = ({fundName, chartData, holdingsData}) => {
    // Get the most recent pct change
    let pctChg = chartData.data.at(-1)


    // Generate tables for best and worst holdings
    let upTable = [];
    let downTable = [];

    let genTableData = (holdingsData, direction) =>{
        let result = [];

        if(direction === "neg"){

            let length = Object.keys(holdingsData["Asset"]).length;
            
            for (let i = length-1; i >= length - 3; i--){
                let asset = holdingsData["Asset"][i];
                let returnToday = holdingsData["Return today (%)"][i];
                let colorClass = "mobile-dash-chg-pos";
                if(returnToday < 0){
                    colorClass = "mobile-dash-chg-neg";
                }
    
                let tableRow = (
                    <tr className='mobile-dash-table-tr' key={asset}>
                        <td className='mobile-dash-table-asset-td'>
                            {asset}
                        </td>
                        <td className={'mobile-dash-table-return-td ' + colorClass}>
                            {returnToday}%
                        </td>
                    </tr>
                )
                result.push(tableRow)
            }
        }else{
            for (let i = 0; i < 3; i++){
                let asset = holdingsData["Asset"][i];
                let returnToday = holdingsData["Return today (%)"][i];
                let colorClass = "mobile-dash-chg-pos";
                if(returnToday < 0){
                    colorClass = "mobile-dash-chg-neg";
                }
    
                let tableRow = (
                    <tr className='mobile-dash-table-tr' key={asset}>
                        <td className='mobile-dash-table-asset-td'>
                            {asset}
                        </td>
                        <td className={'mobile-dash-table-return-td ' + colorClass}>
                            {returnToday}%
                        </td>
                    </tr>
                )
                result.push(tableRow)
            }
        }
        return result;
    }

    upTable = genTableData(holdingsData);
    downTable = genTableData(holdingsData, "neg");

    // Get color for daily change
    let colorClass = 'chg-pos';
    if (pctChg < 0){
        colorClass = 'chg-neg';
    }

    const options = {
        title: {
            enabled: false,
            style: {
                color: "#ffffff",
                display: "none"
            }
        },
        credits : {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            visible: false,
            crosshair: true
        },
        yAxis: {
            lineWidth: 0,
            visible: false,
            crosshair: false
        },
        series: [{
            name: "fund",
            data: chartData.data,//[1, 2, 3, 2, 6, 7, 1, 2, 4, 5, 4, 5, 3, 3, 5, 4, 4, 2, 8, 6, 7, 4, 7, 2, 4, 8, 7, 5, 7, 8, 5, 5, 7, 8, 6, 9],
            color: '#E5DECE', //"#ffff", //"rgba(255, 255, 255, 1)",
            //negativeColor: "#ce8683",
            lineWidth: 0,
        
        }],
        legend : {
            enabled: false
        },
        tooltip : {
            enabled: false
        },
        chart : {
            backgroundColor: null,
            type: 'area',
            marginLeft: -4,
            marginRight: -4,
            spacingLeft: 0,
            spacingRight: 0,
            marginBottom: 0,
            marginTop: 0,//90
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                threshold: -Infinity,
                datagrouping: {
                    enabled: false
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#e5dece40'],
                        //[0.9, 'rgba(255, 255, 255, 0.1)'],
                        [1, 'rgba(255, 255, 255, 0.0)']
                        //[1, '#e5dece']
                    ]
                }
            }
        },
        accessibility: {
            enabled: false
        }
      }

    return(
        <div className='mobile-dash-fund-tile' key={fundName}>
            <div className='mobile-dash-chart-container'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    containerProps={{ style: { height: '100%', width: '100%'} }}
                />
            </div>
            <div className='mobile-dash-info-container'>
                <NavLink to={"/" + fundName.toLowerCase()}>
                    <p className='mobile-dash-fund-tile-title'>{fundName}</p>
                </NavLink>
                <p className={'mobile-dash-fund-tile-return ' + colorClass}>{pctChg.toFixed(2) + "%"}</p>

                <div className='mobile-dash-largest-movers-container'>
                    <div className='mobile-view-top-holdings'>
                        <table className='mobile-dash-table'>
                            <tbody>
                                {upTable}
                            </tbody>
                        </table>
                    </div>
                    <div className='mobile-view-bottom-holdings'>
                    <table className='mobile-dash-table'>
                            <tbody>
                                {downTable}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default MobileDashFundTile;