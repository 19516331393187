import React from "react";

import './MobileStats.css';

import MobileStatsPieChart from "./MobileStatsPieChart/MobileStatsPieChart";

const MobileStats = ({statsData}) => {
    let assetTypeData = statsData.fundStats.assetType.data;
    let regionTypeData = statsData.fundStats.regionType.data;
    let sectorTypeData = statsData.fundStats.sectorType.data;

    return(
        <>
            <div className="mobile-stats-chart-section">
                <p className="mobile-stats-header">Tillgångsfördelning</p>
                <div className="mobile-stats-chart-container">
                    <MobileStatsPieChart data={assetTypeData}/>
                </div>
            </div>
            <div className="mobile-stats-chart-section">
                <p className="mobile-stats-header">Regionsfördelning</p>
                <div className="mobile-stats-chart-container">
                    <MobileStatsPieChart data={regionTypeData}/>
                </div>
            </div>
            <div className="mobile-stats-chart-section">
                <p className="mobile-stats-header">Sektorfördelning</p>
                <div className="mobile-stats-chart-container">
                    <MobileStatsPieChart data={sectorTypeData}/>
                </div>
            </div>

        </>
    )
}

export default MobileStats;