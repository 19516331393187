import react, { useEffect, useState } from "react";
import { Outlet, Routes, Route, Link } from "react-router-dom";
import './Dash.css';

import axios from 'axios';

import DashChart from '../components/DashChart/DashChart.js';
import DashData from "../components/DashData/DashData.js";
import DashHeader from "../components/DashHeader/DashHeader.js";
import IndexBar from "../components/IndexBar/IndexBar.js";
import DashFooter from "../components/DashFooter/DashFooter.js";

import LoadingScreen from "../components/LoadingScreen/LoadingSceen";
import MobileDashFundTile from "../components/Mobile/MobileDashFundTile/MobileDashFundTile";
import MobileIndexBarMenu from "../components/Mobile/MobileIndexBarMnenu/MobileIndexBarMenu";
import MobileDashAumTile from "../components/Mobile/MobileDashAumTile/MobileDashAumTile";
import MobileDashFooter from "../components/Mobile/MobileDashFooter/MobileDashFooter"

function Dash () {
    let [isLoaed, setisLoaded] = useState(false)

    // Initiate clock
    let [time, setTime] = useState()
    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleString());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    // Create varaiables for keeping dash chart data
    let [dashDefGraph, setDashDefGraph] = useState('')
    let [dashBalGraph, setDashBalGraph] = useState('')
    let [dashOffGraph, setDashOffGraph] = useState('')

    // Get dash graph data from API
    const getDailyFundChart = async () => {
        console.log("API called /api/dailyChart/")
        const data = await axios.get('/api/dayilyChart/', {headers: {'Content-Type' : 'application/json'}, withCredentials: false});
        let fundDailyChart = data.data;

        // Get the data for each fund and save to state
        for(let i = 0; i < fundDailyChart.length; i++){
            //console.log(fundDailyChart[i]["fund"]);

            let currentFund = fundDailyChart[i]["fund"]
            //let parsedCurrentFundData = JSON.parse(currentFund)
            let currentFundData = fundDailyChart[i]["data"]

            if(currentFund === "defensiv"){
                //console.log("Current fund is defensiv")
                //console.log(JSON.parse(currentFundData))
                setDashDefGraph(JSON.parse(currentFundData))

            }else if(currentFund === "balanserad"){
                //console.log("Current fund is balanserad")
                setDashBalGraph(JSON.parse(currentFundData))

            }else if(currentFund === "offensiv"){
                //console.log("Current fund is offensiv")
                setDashOffGraph(JSON.parse(currentFundData))
            }
        }
    }

    // Create varaiables for keeping fund data 
    let [holdingsDef, setHoldingsDef] = useState('')
    let [holdingsBal, setHoldingsBal] = useState('')
    let [holdingsOff, setHoldingsOff] = useState('')

    let [dataUpdatedTime, setDataUpdatedTime] = useState('')

    const getFundHoldings = async () => {
        console.log("API called /api/fundHoldings/")
        const data = await axios.get('/api/fundHoldings/', {headers: {'Content-Type' : 'application/json'}, withCredentials: false});
        
        let fundReturn = data.data;

        setDataUpdatedTime(fundReturn[0].time_stamp);

        // Get the data for each fund and save to state
        for(let i = 0; i < fundReturn.length; i++){

            let currentFund = fundReturn[i]["fund"]
            //let parsedCurrentFundData = JSON.parse(currentFund)
            let currentFundData = fundReturn[i]["data"]

            if(currentFund === "defensiv"){
                setHoldingsDef(JSON.parse(currentFundData))

            }else if(currentFund === "balanserad"){
                setHoldingsBal(JSON.parse(currentFundData))

            }else if(currentFund === "offensiv"){
                setHoldingsOff(JSON.parse(currentFundData))
            }
        }
    }      


    let [indexData, setIndexData] = useState('')
    const getIndexData = async () => {
        console.log("API called /api/indexData/")
        const data = await axios.get('/api/indexData/', {headers: {'Content-Type' : 'application/json'}, withCredentials: false});
        
        let indexReturn = data.data;

         // Get the data for each fund and save to state
         for(let i = 0; i < indexReturn.length; i++){
            let currentIndexBundle = indexReturn[i]["name"]
            //let parsedCurrentFundData = JSON.parse(currentFund)
            let currentIndexBundleData = indexReturn[i]["data"]

            if(currentIndexBundle === "indexData"){
                setIndexData(JSON.parse(currentIndexBundleData))
            }
        }
    }

    /// This code makes the API calls simultaneiously, but for some reason the refresh token for Axios interceptor seems to get lost.
    /// Triggers new login on every page realod (f5).

    //let callApi = async () => {
      //  const res = await Promise.all([
        //    getDailyFundChart(),
          //  getFundHoldings(),
        //]);
        //setisLoaded(true)
    //}

    let callApi = async () => {
        await getDailyFundChart();
        await getFundHoldings();

        setisLoaded(true)
    }

    // Run function to get data on page load
    useEffect(() => {
        callApi()
    }, [])


    // Code to be run every 10 mins for gathering new data
    useEffect(() => {
        // check for time, if xx:11, get new data
        if(isLoaed){
            if(time.substr(time.length - 4) === "1:15"){
                console.log("Data auto update at " + time + ".")
                callApi()
            }
        }
    }, [time])


    // Handle when to switch to mobil view
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const breakpoint = 800;

    if(isLoaed === false){
        return(
            <LoadingScreen />
        )
    }else if(windowWidth < breakpoint){
        return(
            <div className='mobile-dash-container'>
                <MobileIndexBarMenu />
                <MobileDashFundTile fundName={"Defensiv"} chartData={dashDefGraph} holdingsData={holdingsDef} />
                <MobileDashFundTile fundName={"Balanserad"} chartData={dashBalGraph} holdingsData={holdingsBal} />
                <MobileDashFundTile fundName={"Offensiv"} chartData={dashOffGraph} holdingsData={holdingsOff} />
                <MobileDashAumTile holdingsDef={holdingsDef} holdingsBal={holdingsBal} holdingsOff={holdingsOff} />
                <MobileDashFooter time={time} dataUpdatedTime={dataUpdatedTime} />
        
            </div>
        )
    }else{
        return (
            <>
                <div className="index-bar-container">
                    <IndexBar />
                </div>
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div className="dash-fund-container">
                            <DashHeader id="defensiv" fund="FondNavigator 0-100 Defensiv" pct_chg={dashDefGraph.data.at(-1)} />
                            <div className="graph-box">
                                <DashChart id="grp_defensiv" title="FondNavigator 0-100 Defensiv" data={dashDefGraph}/>
                            </div>
                            <DashData id="inf_defensiv" data={holdingsDef} />
                        </div>
                        <div className="dash-fund-container">
                            <DashHeader id="balanserad" fund="FondNavigator 0-100 Balanserad" pct_chg={dashBalGraph.data.at(-1)} />
                            <div className="graph-box">
                                <DashChart id="grp_balanserad" title="FondNavigator 0-100 Balanserad" data={dashBalGraph}/>
                            </div>
                            <DashData id="inf_balanserad" data={holdingsBal} />
                        </div>
                        <div className="dash-fund-container">
                            <DashHeader id="offensiv" fund="FondNavigator 0-100 Offensiv" pct_chg={dashOffGraph.data.at(-1)} />
                            <div className="graph-box">
                                <DashChart id="grp_offensiv" title="FondNavigator 0-100 Offensiv" data={dashOffGraph}/>
                            </div>
                            <DashData id="inf_offensiv" data={holdingsOff} />
                        </div>
                    </div>
                </div>
                <div className="footer-container">
                    <DashFooter data={dashBalGraph}/>
                </div>
            </>
        );
    }
}

export default Dash;

// <p style={{color: "white"}}>{dashDefGraph}</p>


