import React from "react";

import './Soundboard.css';

import useSound from 'use-sound';

import bringSound from './audio/Bring.wav';
import diffusSound from './audio/Diffus.wav';
import groanSound from './audio/Groan.wav';
import ringSound from './audio/Ringhalla.wav';
import schmackSound from './audio/Schmack.wav';
import schplingSound from './audio/Schpling.wav';



const Soundboard = () => {

    let [bring] = useSound(bringSound);
    function playBring(){
        bring();
    }

    let [diffus] = useSound(diffusSound);
    function playDiffus(){
        diffus();
    }

    let [groan] = useSound(groanSound);
    function playGroan(){
        groan();
    }

    let [ring] = useSound(ringSound);
    function playRing(){
        ring();
    }

    let [schmack] = useSound(schmackSound);
    function playSchmack(){
        schmack();
    }
    
    let [schpling] = useSound(schplingSound);
    function playSchpling(){
        schpling();
    }

    return(
        <div className="soundboardContainer">
            <div className="sounboardButton" onClick={() => playBring()}>
                <p>Bring</p>
            </div>
            <div className="sounboardButton" onClick={() => playDiffus()}>
                <p>Diffus</p>
            </div>
            <div className="sounboardButton" onClick={() => playGroan()}>
                <p>Groan</p>
            </div>
            <div className="sounboardButton" onClick={() => playRing()}>
                <p>Ring hallå</p>
            </div>
            <div className="sounboardButton" onClick={() => playSchmack()}>
                <p>Schmack</p>
            </div>
            <div className="sounboardButton"  onClick={() => playSchpling()}>
                <p>Schpling</p>
            </div>
        </div>
    )
}

export default Soundboard;