import React, { useState, useEffect } from 'react';

import './MobileIndexTile.css';


const MobileIndexTile = ({ indexName, indexPctChg, indexCurrency }) => {
    
    let currReturnClass = "chg-pos";
    if (indexPctChg < 0){
        currReturnClass = "chg-neg";
    }

    let currFlagUrl = "https://hatscripts.github.io/circle-flags/flags/se.svg";
    if (indexCurrency === "USD"){
        currFlagUrl = "https://hatscripts.github.io/circle-flags/flags/us.svg";
    }else if(indexCurrency === "EUR"){
        currFlagUrl = "https://hatscripts.github.io/circle-flags/flags/eu.svg";
    }

    return(
        <div className='mobile-index-tile' key={indexName}>
            <div className='mobile-index-item'>
                <img src={currFlagUrl} width="15" alt="flag" className="index-item-flag" />
                <p className='mobile-index-item-label'>{indexName}</p>
                <p className={currReturnClass}>{indexPctChg}%</p>
            </div>
        </div>
    );
}

export default MobileIndexTile;