import React from "react";
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import './MobileFundChart.css'


const MobileFundChart = (props) => {
    const {data, indexData, fundName} = props;
    //const [selectedDate, setSelectedDate] = React.useState("");
    let [assetReturn, setAssetReturn] = React.useState(0.00);

    let [indexVisible, setIndexVisible] = React.useState(true)

    let handleIndexToggle = () => {
        if(indexVisible){
            setIndexVisible(false)
        }else if(!indexVisible){
            setIndexVisible(true)
        }
    }

    // Options to set the labels to swedish
    Highcharts.setOptions({
        lang: {
            months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],
            shortMonths: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            weekdays: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag']
        }
    });

    const options = {
        title: {
            enabled: false
        },
        credits : {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            visible: true,
            crosshair: true,
            labels: {
                style: {
                    color: '#dbdbdb',
                    fontWeight: 'light'
                },
                //overflow: 'justify',
                align: 'left',//

                formatter: function () {
                    const tickPositions = this.axis.tickPositions;
                    const currentIndex = tickPositions.indexOf(this.pos);
                    if (currentIndex === 0 || Highcharts.dateFormat('%Y', this.value) !== Highcharts.dateFormat('%Y', tickPositions[currentIndex - 1])) {
                      return Highcharts.dateFormat('%Y<br>%b %e', this.value);
                    }else{
                    return Highcharts.dateFormat('‎ <br>%b %e', this.value);
                    }
                  },
                  useHTML: false,
                  y: 20//-16//20 // add spacing to make room for the year label       
                
            },
            tickWidth: 0,
            tickLength: 0,
            events: {
                afterSetExtremes(e) { // Function to get the current selected date interval
                    //setSelectedDate(Highcharts.dateFormat(null, e.min) + " till " + Highcharts.dateFormat(null, e.max))
  
                }
            },
        },
        yAxis: {
            lineWidth: 0,
            visible: true,
            crosshair: true,
            gridLineColor: 'transparent',
            labels: {
                style: {
                    color: '#dbdbdb',
                    fontWeight: 'light'
                },
                x: -14   
            }
        },
        series: [{
            name: fundName,
            data: data,
            color: "rgba(255, 255, 255, 1)",
            lineWidth: 0.0, //0.5
            compare: 'percent', // This calculates the percentage return even though the input data is in SEK. E.g the y-axis values are calculated to percent instead of showing raw SEK-data.
            lastVisiblePrice: {
                enabled: true,
                label: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function() {
                    //let max = this.y;
                    //console.log(max);
                }
            },
        },
        {
            name: "Fondens jämförelseindex",
            data: indexData,
            type: 'line',
            color: '#F7BE6F',
            lineWidth: 2,
            compare: 'percent',
            visible: indexVisible
        }],
        legend : {
            enabled: false
        },
        tooltip : {
            enabled: true,
            backgroundColor: '#151825',
            style: {
                color: '#dbdbdb',
            },
            valueDecimals: 2,
            valueSuffix: '%',
            pointFormat: '{series.name}</span>: {point.change}%<br/>' // Use this if the graph is populated with SEK-data and the starting point is Eg. NAV 100
      
        },
        rangeSelector: {
            enabled: true, // This will be set to false in Metafore implementation. Custom date range selector in separate component is needed.
            verticalAlign: 'bottom',
            buttonTheme: { // styles for the buttons
                fill: '#151825',
                stroke: 'none',
                'stroke-width': 0,
                r: 7,
                style: {
                    color: '#dbdbdb',
                    fontWeight: 'light'
                },
                states: {
                    hover: {
                        style: {
                            color: "#262937"
                        }
                    },
                    select: {
                        fill: '#262937',
                        style: {
                            color: 'white',
                            fontWeight: 'light'
                        }
                    }
                    //disabled: { ... }
                }
            },
            inputPosition: {
                align: 'left',
                x: 20,
                y: 0
            },
            buttonPosition: {
                align: 'right',
                x: -10,
                y: 0
            },
            inputBoxBorderColor: 'gray',
            inputBoxWidth: 100,
            inputBoxHeight: 15,
            //inputBoxBorderColor: '#dbdbdb',
            inputStyle: {
                color: '#dbdbdb',
                fontWeight: 'light'
            },
            labelStyle: {
                color: '#dbdbdb',
                fontWeight: 'light',
                display: 'none',
                text: "text"
            },
            buttons: [{
                type: 'month',
                count: 1,
                text: '1 månad',
                
            },{
                type: 'month',
                count: 3,
                text: '3 månader',
            }, {
                type: 'month',
                count: 6,
                text: '6 månader',
            }, {
                type: 'ytd',
                text: 'i år',
            }, {
                type: 'year',
                count: 1,
                text: '1 år',
            }, {
                type: 'year',
                count: 3,
                text: '3 år',
            }, {
                type: 'year',
                count: 5,
                text: '5 år',
            }, {
                type: 'all',
                text: 'max',
            }],
            //selected: 3 // This messes with the setState in the chart event render
        },
        chart : {
            backgroundColor: null,
            type: 'area',
            marginLeft: 0,
            marginRight: 0,
            spacingLeft: 0,
            spacingRight: 0,
            style: {
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold'
            },
            events: {
                render(e) {
                    let points = e.target.series[0].points; // This is the points currently shwon in the graph
                    
                    let minY = Object.values(points)[0]['y'];
                    let maxY = Object.values(points)[points.length - 1]['y'];
                    let returnPct = ((maxY / minY - 1) * 100).toFixed(2);
                    setAssetReturn(returnPct);
                }
            },
            
            //zoomType: 'x'
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                threshold: -Infinity, //Sets gradient to alwas have bottom on x-axis and not on y-value 0.
                datagrouping: {
                    enabled: false
                },
                
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, 'rgba(255, 255, 255, 0.8)'],
                        [0.9, 'rgba(255, 255, 255, 0.1)'],
                        [1, 'rgba(255, 255, 255, 0.0)']
                    ]
                }
            }
        },
        accessibility: {
            enabled: false
        }
    }

    // Set color class for fund return
    let fundReturncolorClass = "pct_pos";
    if(assetReturn < 0){
        fundReturncolorClass = "pct_neg";
    }

    return(
        <div className="mobile-fund-chart-container">
            <p className="mobile-fund-view-header" onClick={handleIndexToggle}>FondNavigator 0-100 {fundName}</p>
            <div className="mobile-fund-chart-return-box">
                <div className="mobile-fund-chart-return-text">
                    <p>Utveckling för vald period</p>
                    <p className={"fund-view-return " + fundReturncolorClass}>{assetReturn}%</p>
                </div>
            </div>
            <div className="mobile-fund-chart-box">
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                    containerProps={{ style: { height: '100%', width: '100%'} }}
                />
            </div>
        </div>
    )
}


export default MobileFundChart;