import React, { useEffect, useState } from "react";
import { Outlet, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';

import './Fund.css'

import IndexBar from "../components/IndexBar/IndexBar";
import FundChart from "../components/FundChart/FundChart";
import FundTable from "../components/FundTable/FundTable";
import FundStats from "../components/FundStats/FundStats";

import MobileIndexBarMenu from "../components/Mobile/MobileIndexBarMnenu/MobileIndexBarMenu";
import MobileFundChart from "../components/Mobile/MobileFundChart/MobileFundChart";
import MobileFundTable from "../components/Mobile/MobileFundTable/MobileFundTable";
import MobileStatsPieChart from "../components/Mobile/MobileStats/MobileStats";

import LoadingScreen from "../components/LoadingScreen/LoadingSceen";

function Fund(props) {
    let [isLoaed, setisLoaded] = useState(false);
    
    let {fundName } = props;

    // Create varaiables for keeping fund holdings and return data 
    let [holdings, setHoldings] = useState('')
    const getFundHoldings = async () => {
        console.log("API called /api/fundHoldings/")
        const data = await axios.get('/api/fundHoldings/?fund='+ fundName.toLowerCase(), {headers: {'Content-Type' : 'application/json'}, withCredentials: false});

        let fundReturn = JSON.parse(data.data[0].data);
        setHoldings(fundReturn);
    }

    // Create varaiables for keeping fund and index chart data and return data 
    let [historiyChartData, setHistoryChartData] = useState('')
    let [historiyIndexData, setHistoryIndexData] = useState('')
    const getHistoriyChartData = async () => {
        console.log("API called /api/historicChart/")
        const data = await axios.get('/api/historicChart/?fund='+ fundName.toLowerCase(), {headers: {'Content-Type' : 'application/json'}, withCredentials: false});

        let fundHistory = JSON.parse(data.data[0].data);

        setHistoryChartData(fundHistory.fundData.chartData.graph.series[0].data);
        setHistoryIndexData(fundHistory.fundData.chartData.indexGraph.series[0].data);
    }

    // Create varaiables for keeping fund stats data 
    let [fundStats, setFundStats] = useState('')
    const getFundStats = async () => {
        console.log("API called /api/fundStats/")
        const data = await axios.get('/api/fundStats/?fund='+ fundName.toLowerCase(), {headers: {'Content-Type' : 'application/json'}, withCredentials: false});

        let fundStats = JSON.parse(data.data[0].data);
        setFundStats(fundStats);

        //console.log(fundStats)
    }

    let callApi = async () => {
        await getFundHoldings();
        await getHistoriyChartData();
        await getFundStats();

        setisLoaded(true)
    }

    // Run function to get data on page load
    useEffect(() => {
        setisLoaded(false)
        callApi()
    }, [fundName])

    // Handle when to switch to mobil view
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    const breakpoint = 800;

    if(isLoaed === false){
        return(
            <LoadingScreen />
        )
    }else if(windowWidth < breakpoint){
        return(
            <>
                <MobileIndexBarMenu />
                <div className="mobile-fund-container">
                    <MobileFundChart data={historiyChartData} indexData={historiyIndexData} fundName={fundName}/>
                </div>
                <div className="mobile-table-container">
                    <MobileFundTable fundData={holdings}/>
                </div>
                <div className="mobile-stats-container">
                    <MobileStatsPieChart statsData={fundStats} />
                </div>
            </>
        )
    }else{
        return(
            <>
                <div className="index-bar-container">
                    <IndexBar />
                </div>
                <div className="fund-view-container">
                    <div className="fund-view-content">
                        <div className="fund-view-graph-container">
                            <FundChart data={historiyChartData} indexData={historiyIndexData} fundName={fundName}/>
                        </div>
                        <div className="fund-view-table-container">
                            <p className="fund-view-header">Innehav & utveckling</p>
                            <FundTable fundData={holdings}/>
                        </div>
                        <div className="fund-view-info-container">
                            <p className="fund-view-header">Statistik & fördelning</p>
                            <div className="fund-stats-container">
                                <FundStats statsData={fundStats}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            
        );
    }
}

export default Fund;
