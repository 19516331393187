import {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import useSound from 'use-sound';

import './LogIn.css';
import fnlogo from './FNLogo.svg';

import ringSound from './ring.wav';

import Soundboard from "./Soundboard/Soundboard";

const LogIn = ({}) => {
  const navigate = useNavigate();

  let [failedLogIn, setFailedLogIn] = useState(false);

  let [showSoundboard, setShowSoundboard] = useState(false);

  function toggleSoundboard() {
    setShowSoundboard(!showSoundboard);
  };

  let [mousePos, setMousePos] = useState({x: 1, y: 1});
  useEffect(() => {
    const handleMouseMove = (event) => {
        setMousePos({ x: event.clientX, y: event.clientY });
        document.querySelector(".log-in-page-gradient-container").style.setProperty("--x", mousePos.x + "px");          document.querySelector(".log-in-page-gradient-container").style.setProperty("--y", mousePos.y + "px");
        //console.log(mousePos.x)
    };
    
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
        );
      };
    }, [mousePos]);

    const [playSound] = useSound(ringSound);
    // handle forgot password
    function forgottPass() {
      //alert("Synd för dig. Kontakta supporten i Mumbai.");
      playSound();
    }

    let handleSubmit = async (e) =>{
      e.preventDefault();
      let user = e.target.username.value;
      let pass = e.target.password.value;

      let userObj = {username : user, password : pass}

      logIn(userObj)

      

    async function logIn(user){
      //Axios call to BE
      try{
        const uninterceptedAxiosInstance = axios.create();
        let {data} = await uninterceptedAxiosInstance.post('/token/', user, {headers: {'Content-Type' : 'application/json'}, withCredentials: false});
    
        // Check if user entered correwct credentials
        //console.log(data)
        // Initialize the access & refresh token in localstorage.      
        localStorage.clear();
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;

        //console.log(localStorage)
        
        navigate("/");

        //setLoggedInUser(userInfo["userFirstName"])
        //window.location.href = '/user'
        //return redirect("/user")
      }
      catch(error)
      {
        console.log(error)
        if(error.response.status === 401){
          setFailedLogIn(true)
        }
      }
    }
  
  }

    // Handle when to switch to mobil view
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const breakpoint = 800;

    if(windowWidth < breakpoint){
      return(
        <div className="log-in-page-container">
          <div className="log-in-page-gradient-container" />

          

          <div className="log-in-form-container-mobile">
            <div className="fn-logo-container-mobile">
              <img src={fnlogo}></img>
            </div>

            <div className="log-in-content-hero-mobile">
              <p className="hero-heading-mobile">FondNavigator</p>
              <p className="hero-heading-mobile gradient-text-mobile">Dashboard</p>
              <p className="hero-sub-text-mobile">Utveckling</p>
              <p className="hero-sub-text-mobile">i realtid</p>
              <p className="hero-text-mobile"></p>
            </div> 

            <form onSubmit={handleSubmit}>
              <p className="log-in-form-label">E-post</p>
              <input className="log-in-form-text-input" type="text" name="username" required />
              <p className="log-in-form-label">Lösenord</p>
              <input className="log-in-form-text-input" type="password" name="password" required />
              <input className="log-in-button" type="submit" value="Logga in" />
              <p className="text-forgotten-password" onClick={forgottPass}>Glömt lösenord?</p>
            </form>
            {failedLogIn && (
              <div className="failed-login-box">
                <p>Misslyckad inloggning. Har du angett korrekt e-post och lösneord?</p>  
              </div>
            )}
            <div className="card-footer-mobile">
              <p onClick={() => toggleSoundboard()}>V. 1.0.0.1</p>
            </div>
          </div>
        </div>
      )
    }else{
      return (
        <div className="log-in-page-container">
          <div className="log-in-page-gradient-container" />
            <div className="log-in-content-hero">
              <p className="hero-heading shimmer">FondNavigator</p>
              <p className="hero-heading gradient-text">Dashboard</p>
              <p className="hero-sub-text">Utveckling</p>
              <p className="hero-sub-text">i realtid</p>
              <p className="hero-text"></p>
            </div>
            <div className="log-in-content-card">
                <div className="fn-logo-container">
                    <img src={fnlogo}></img>
                </div>
                <div className="log-in-form-container">
                    <form onSubmit={handleSubmit}>
                      <p className="log-in-form-label">E-post</p>
                      <input className="log-in-form-text-input" type="text" name="username" required />
                      <p className="log-in-form-label">Lösenord</p>
                      <input className="log-in-form-text-input" type="password" name="password" required />
                      <input className="log-in-button" type="submit" value="Logga in" />
                      <p className="text-forgotten-password" onClick={forgottPass}>Glömt lösenord?</p>
                    </form>
                  </div>
                  {failedLogIn && (
                    <div className="failed-login-box">
                      <p>Misslyckad inloggning. Har du angett korrekt e-post och lösneord?</p>  
                    </div>
                  )}
                  <div className="card-footer">
                    <p onClick={() => toggleSoundboard()}>V. 1.0.0.1</p>
                  </div>
                  {showSoundboard && (
                    <Soundboard />
                  )}
            </div>
        </div>
        
    );
    }
  }
  
  export default LogIn;


//<input className="log-in-button" type="button" value="Hämta data" onClick={getData} />
//<input className="log-in-button" type="button" value="Hämta daily fund chart" onClick={getDailyFundChart} />
//<input className="log-in-button" type="button" value="Addera ny daily fund chart" onClick={addDailyFundChart} />
//<input className="log-in-button" type="button" value="Logga ut" onClick={logOut} />