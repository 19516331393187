import React, { useState } from 'react';

import './MobileDashFooter.css';

const MobileDashFooter = ({time, dataUpdatedTime}) => {




    return(
        <div className='mobile-footer-container'>
            <div className='mobile-footer-section'>
                <p className='mobile-footer-value'>{time}</p>
            </div>
            <div className='mobile-footer-section'>
                <p className='mobile-footer-value'>Uppd. {dataUpdatedTime}</p>
            </div>
        </div>
        
    )
}

export default MobileDashFooter;