import React, { useState } from "react";
import './DashHeader.css';

export default function DashHeader (props) {
    const {id, fund, pct_chg} = props

    var pct_chg_class = "";
    if(pct_chg >= 0){
        pct_chg_class = "pct-chg-pos";
    } else {
        pct_chg_class = "pct-chg-neg";
    }
    return(
        <>
            <div>
                <a href={id}> 
                    <p id={id} className="dash-heading">{fund}</p>
                </a>
                <p className={pct_chg_class}>{parseFloat(pct_chg).toFixed(2)}%</p>
            </div>
        </>
    )
}

// Change the id in the href to '/' to make navigation to the Fund page inactivated