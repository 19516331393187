import React, { useState, useEffect } from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";

import './IndexBar.css'

import menuIcon from './menu-icon.svg';

export default function IndexBar() {

    // Handle menu expansion
    let [isMenuExpand, setIsMenuExpand] = useState(false);
    let handleMenuExpand = () => {
        if(isMenuExpand){
            setIsMenuExpand(false)
        }else if(!isMenuExpand){
            setIsMenuExpand(true)
        }
    }

    let [isLoaded, setisLoaded] = useState(false)

    // Initiate clock
    let [time, setTime] = useState()
    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleString());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    // Get index data
    const getIndexData = async () => {
        console.log("API called /api/indexData/ from IndexBar")
        const data = await axios.get('/api/indexData/', {headers: {'Content-Type' : 'application/json'}, withCredentials: false});        
        let indexReturn = data.data;

         // Get the index data and save to state
         for(let i = 0; i < indexReturn.length; i++){
            let currentIndexBundle = indexReturn[i]["name"]
            let currentIndexBundleData = indexReturn[i]["data"]

            if(currentIndexBundle === "indexData"){
                // Call function which generates index tiles in jSX and saves them to state array "indexTiles"
                genIndexItems(JSON.parse(currentIndexBundleData));
            }
        }
    }

    let callApi = async () => {
        setisLoaded(false);
        await getIndexData();
        setisLoaded(true);
    }

    // Run function to get data on page load
    useEffect(() => {
        callApi();
    }, [])

    useEffect(() => {
        // check for time, if xx:11, get new data
        if(isLoaded){
            if(time.substr(time.length - 4) === "1:15"){
                console.log("Index data auto update at " + time + " from Indexbar.")
                callApi()
            }
        }
    }, [time])

    // Log out API call
    const logOut = async () => {
        let data = await axios.post('/api/logout/',{refresh_token:localStorage.getItem('refresh_token')} ,{headers: {'Content-Type': 'application/json'}}, {withCredentials: true});
        localStorage.clear();
        axios.defaults.headers.common['Authorization'] = null;
        console.log("Logout successfull. Cleared local storage.")
        console.log(localStorage)

        window.location.href = '/login'
    }

    // Function to generate index tiles and save them to state
    let [indexTiles, setIndexTiles] = useState([])
    function genIndexItems(data) {
        let index_items = []
        for (let i = 0; i < 6; i++) {

            // Set correct pct_chg class
            var pct_chg_class = "pct_pos index-item-return";
            if(data["Return today (%)"][i] < 0){
                pct_chg_class = "pct_neg index-item-return";
            }

            //Set correct flag URL
            var src_flag = "https://hatscripts.github.io/circle-flags/flags/se.svg";
            if(data.FX[i] === "USD"){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/us.svg";
            }else if(data.FX[i] === "EUR"){
                src_flag = "https://hatscripts.github.io/circle-flags/flags/eu.svg";
            }else if(data.FX[i] === "DKK"){
            src_flag = "https://hatscripts.github.io/circle-flags/flags/dk.svg";
            }

            var index_item = (
                <div className="index-display" key={"IndexBar " + data.Asset[i]}>
                    <div className="index-item">
                        <img src={src_flag} width="15" alt="flag" className="index-item-flag" />
                        <p className="index-item-asset">{data.Asset[i]}</p>
                        <p className={pct_chg_class}>{data["Return today (%)"][i]}%</p>
                    </div>
                </div>
            )
            // Add index items to state array
            index_items.push(index_item)
        }
        setIndexTiles(index_items);
    };

   if(!isLoaded){
    return(
        /* LOADING PLACEHOLDER */
        <div className="index-menu-bar-container">
            <div className="index-bar">
                {[0, 1, 2, 3, 4, 5].map(i => {
                    return(
                        <div className="index-display" key={i}>
                            <div className="index-item">
                                <div className="index-item-flag">
                                    <div className="index-bar-item-loading-placeholder-flag shimmer" />
                                </div>
                                <div className="index-item-asset">
                                    <div className="index-bar-item-loading-placeholder-asset shimmer" />
                                </div>
                            </div>
                        </div>  
                    )
                })}     
            </div>
            <div className="menu-bar">
                <div className="menu-icon" onClick={handleMenuExpand}>
                    <img src={menuIcon} alt="menu" />
                </div>
                {isMenuExpand && (
                    <div className="menu-container">
                        <div className='menu-item-large'>
                            <NavLink to="/" className={"white-text"}>
                                <p>Dashboard</p>
                            </NavLink>
                        </div>
                        <div className='menu-item'>
                            <NavLink to="/defensiv" className={"white-text"}>
                                <p>Defensiv</p>
                            </NavLink>
                        </div>
                        <div className='menu-item'>
                            <NavLink to="/balanserad" className={"white-text"}>
                                <p>Balanserad</p>
                            </NavLink>
                        </div>
                        <div className='menu-item'>
                            <NavLink to="/offensiv" className={"white-text"}>
                                <p>Offensiv</p>
                            </NavLink>
                        </div>
                        <div className='menu-item-large'>
                            <NavLink to="/history" className={"white-text"}>
                                <p>Historik</p>
                            </NavLink>
                        </div>
                        <div className='menu-log-out' onClick={() => logOut()}>
                            <p>Logga ut</p>
                        </div>
                    </div>   
                )}
                
            </div>
        </div>
    )
    }else{
        return (
            <div className="index-menu-bar-container">

                {/* Index display */}
                <div className="index-bar">
                    {indexTiles}
                    {/*{gen_index_items(indexData)}*/}
                </div>

                {/* Menu display */}
                <div className="menu-bar">
                    <div className="menu-icon" onClick={handleMenuExpand}>
                        <img src={menuIcon} alt="menu" />
                    </div>
                    {isMenuExpand && (
                        <div className="menu-container">
                            <div className='menu-item-large'>
                                <NavLink to="/" className={"white-text"}>
                                    <p>Dashboard</p>
                                </NavLink>
                            </div>
                            <div className='menu-item'>
                                <NavLink to="/defensiv" className={"white-text"}>
                                    <p>Defensiv</p>
                                </NavLink>
                            </div>
                            <div className='menu-item'>
                                <NavLink to="/balanserad" className={"white-text"}>
                                    <p>Balanserad</p>
                                </NavLink>
                            </div>
                            <div className='menu-item'>
                                <NavLink to="/offensiv" className={"white-text"}>
                                    <p>Offensiv</p>
                                </NavLink>
                            </div>
                            <div className='menu-item-large'>
                                <NavLink to="/history" className={"white-text"}>
                                    <p>Historik</p>
                                </NavLink>
                            </div>
                            <div className='menu-log-out' onClick={() => logOut()}>
                                <p>Logga ut</p>
                            </div>
                        </div>   
                    )}  
                </div>

            </div>
        );
    }
}