import React from "react";
import './LoadingScreen.css';

const LoadingScreen = ({}) => {

    return(
        <div className="loading-screen-container">
            <div className="loading-screen-text-container">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen;