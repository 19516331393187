import React, { useState } from 'react';

import './MobileDashAumTile.css';

const MobileDashAumTile = ({holdingsDef, holdingsBal, holdingsOff}) => {
    let [aumDef, setAumDef] = useState(0);
    let [aumBal, setAumBal] = useState(0);
    let [aumOff, setAumOff] = useState(0);

    // Calculate total AUM
     let calcAum = (positionsSek) => {
        let aum = 0;
        for(let i=0; i < Object.keys(positionsSek["Position value (SEK)"]).length; i++){
            aum += positionsSek["Position value (SEK)"][i];
        }
        return aum;
    }
    
    useState(() => {
        setAumDef(calcAum(holdingsDef))
        setAumBal(calcAum(holdingsBal))
        setAumOff(calcAum(holdingsOff))
    }, []);
    



    return(
        <div className='mobile-dash-aum-tile-container'>
            <p className='mobile-dash-aum-header-small'>Total AUM</p>
            <p className='mobile-dash-aum-header'>{(aumDef + aumBal + aumOff).toLocaleString('se-SE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} SEK</p>
            <table className='mobile-aum-table'>
                <tbody>
                    <tr className='mobile-aum-table-tr'>
                        <td className='mobile-aum-table-td'>
                            <p>Defensiv</p>
                        </td>
                        <td className='mobile-aum-table-td align-right'>
                            <p>{aumDef.toLocaleString('se-SE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} SEK</p>
                        </td>
                    </tr>
                    <tr className='mobile-aum-table-tr'>
                        <td className='mobile-aum-table-td'>
                            <p>Balanserad</p>
                        </td>
                        <td className='mobile-aum-table-td align-right'>
                            <p>{aumBal.toLocaleString('se-SE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} SEK</p>
                        </td>
                    </tr>
                    <tr className='mobile-aum-table-tr'>
                        <td className='mobile-aum-table-td'>
                            <p>Offensiv</p>
                        </td>
                        <td className='mobile-aum-table-td align-right'>
                            <p>{aumOff.toLocaleString('se-SE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} SEK</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MobileDashAumTile;