import React, { forwardRef } from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import './FundChartPrint.css';

const FundChartPrint = forwardRef((props, ref) => {
    // Get dates from the JS timestamps
    let minDate = new Date(props.chartMin).toLocaleDateString();
    let maxDate = new Date(props.chartMax).toLocaleDateString();

    let colorClass = "print-chg-pos";
    if(props.assetReturn < 0){
        colorClass = "print-chg-neg";
    }

    let showIndex = true;
    if(props.indexLineWidth === 0){
        showIndex = false;
    }

    const options = {
        credits: {
            enabled: false
        },
        title: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            visible: true,
            crosshair: false,
            labels: {
                style: {
                    color: '#2F3342',
                    fontWeight: 'light'
                },
                //overflow: 'justify',
                align: 'center',

                formatter: function () {
                    const tickPositions = this.axis.tickPositions;
                    const currentIndex = tickPositions.indexOf(this.pos);
                    if (currentIndex === 0 || Highcharts.dateFormat('%Y', this.value) !== Highcharts.dateFormat('%Y', tickPositions[currentIndex - 1])) {
                      return Highcharts.dateFormat('%Y<br>%b %e', this.value); //return Highcharts.dateFormat('%Y<br>%b %e', this.value);
                    }else{
                    return Highcharts.dateFormat('‎ <br>%b %e', this.value); //return Highcharts.dateFormat('‎ <br>%b %e', this.value);
                    }
                  },
                  useHTML: false,
                  y: 20//-16//20 // add spacing to make room for the year label       
            },
            tickWidth: 1,
            tickLength: 1,

            min: props.chartMin,
            max: props.ChartMax
        },
        yAxis: {
            lineWidth: 0,
            visible: true,
            crosshair: true,
            gridLineColor: 'transparent',
            labels: {
                format: '{value}%',
                distance: '150%',
                
                style: {
                    color: '#2F3342',
                    fontWeight: 'light'
                },
                x: 28   
            }
        },
        series: [{
            name: "FondNavigator 0-100 " + props.fundName,
            lineWidth: 2,
            type: 'area',
            color: '#2f3342',
            lineColor: '#2f3342',
            data: props.data,
            compare: 'percent',
        },
        {
            name: "Fondens jämförelseindex",
            showInLegend: showIndex,
            data: props.indexData,
            type: 'line',
            color: '#F7BE6F',
            lineWidth: props.indexLineWidth,
            compare: 'percent'
        }],
        legend: {
            enabled: true
        },
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        chart: {
            backgroundColor: null,
            type: 'area',
            marginLeft: 0,
            marginRight: 28,
            spacingLeft: 0,
            spacingRight: 0,
            style: {
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold'
            },
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                threshold: -Infinity, //Sets gradient to alwas have bottom on x-axis and not on y-value 0.
                datagrouping: {
                    enabled: false
                },
                
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, '#2F3342'],
                        [0.7, '#2f334226'],
                        [1, 'rgba(255, 255, 255, 0.0)']
                    ]
                }
            }
        }

    }
    return(
        <>
            <div className='fund-chart-print-page-overlay'>

            </div>
            <div ref={ref} className="fund-chart-print-page">
                <div className="fund-chart-print-page-content">
                    <div className='fund-chart-page-content-header-container'>
                        <p className='fund-chart-page-content-header'>FondNavigator 0-100 {props.fundName}</p>
                        <div className='fund-chart-print-return-section'>
                            <p className='fund-chart-page-content-sub-header'>Utveckling från {minDate} till {maxDate}:</p>
                            <p className={'fund-chart-page-content-sub-header ' + colorClass}>{props.assetReturn}%</p>
                        </div>
                    </div>
                    <div className='fund-chart-print-chart-container'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            options={options}
                            containerProps={{ style: { height: '100%', width: '100%'} }}
                        />
                    </div>
                </div>
               
            </div>
        </>
    );
});

export default FundChartPrint;