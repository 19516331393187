import react, { useEffect, useState } from "react";

import axios from "axios";

import './History.css';

import LoadingScreen from "../../components/LoadingScreen/LoadingSceen";
import HistoryTile from "../../components/HistoryTile/HistoryTile";
import IndexBar from "../../components/IndexBar/IndexBar";
import MobileIndexBarMenu from "../../components/Mobile/MobileIndexBarMnenu/MobileIndexBarMenu";
import MobileHistoryTile from "../../components/Mobile/MobileHistoryTile/MobileHistoryTile";

const History = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    let [textIsExpanded, setTextisExpanded] = useState(false);


    let expandMobileText = () => {
        if(textIsExpanded){
            setTextisExpanded(false)
        }else if(!textIsExpanded){
            setTextisExpanded(true)
        }
    }

    // Create arrays for each fund
    let [predBal, setPredBal] = useState([]);
    let [predDef, setPredDef] = useState([]);
    let [predOff, setPredOff] = useState([]);

    const getEodPrediction = async () => {
        console.log("API called /api/eodPrediction/")
        const data = await axios.get('/api/eodPrediction/', {headers: {'Content-Type' : 'application/json'}, withCredentials: false});
        
        let eodPredictions = data.data;
        
        // Reset prediction arrays before running function to prevent duplication
        setPredBal([])
        setPredDef([])
        setPredOff([])

        // Loop trough the data and append to the respective fund
        for(let i = 0; i < eodPredictions.length; i++){
            if(eodPredictions[i].fund === "balanserad"){
                setPredBal(predBal => [...predBal, eodPredictions[i]])
            }else if(eodPredictions[i].fund === "defensiv"){
                setPredDef(predDef => [...predDef, eodPredictions[i]])
            }else if(eodPredictions[i].fund === "offensiv"){
                setPredOff(predOff => [...predOff, eodPredictions[i]])
            }
        }
    }      

    let callApi = async () => {
        await getEodPrediction();
        setIsLoaded(true)
    }

    // Run function to get data on page load
    useEffect(() => {
        // Call API for getting prediction history
        callApi()
    }, [])

    // Handle when to switch to mobil view
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    const breakpoint = 800;

    if(isLoaded === false){
        return(
            <LoadingScreen />
        );
    }else if(windowWidth < breakpoint){
        return(
            <div className="history-mobile-container">
                <MobileIndexBarMenu />
                <div className="history-mobile-intro-section">
                    <div className="history-mobile-intro-section-heading">
                            <p>Tidigare prognoser</p>
                        </div>
                        {textIsExpanded && (
                            <div className="history-mobile-intro-section-text">
                                <p>De prognostiserade värdena som genereras för dagens förändring sparas nedan för att kunna jämföras med faktiskt utfall. Någon avvikelse är att vänta då processen för NAV-sättning skiljer sig åt från den metod som nyttjas för prognostisering. En alltför stor diskrepans bör dock undersökas närmre.</p>
                                <p>Notera också att en fonds allokering kan påverka precisionen i prognostiseringen. En större andel icke börshandlade instrument kommer att försämra tillförlitligheten.</p>
                                <p className="history-mobile-expand-text-button" onClick={expandMobileText}>Dölj</p>
                            </div>
                        )}
                        {!textIsExpanded && (
                            <div className="history-mobile-intro-section-text-collapsed">
                                <p className="history-mobile-text-collapsed">De prognostiserade värdena som genereras för dagens förändring sparas nedan för att kunna jämföras med faktiskt utfall. Någon avvikelse är att vänta då processen för NAV-sättning skiljer sig åt från den metod som nyttjas för prognostisering. En alltför stor diskrepans bör dock undersökas närmre.</p>
                                <p className="history-mobile-expand-text-button" onClick={expandMobileText}>Läs mer</p>
                            </div>
                        )}
                        
                </div>
                <div className="mobile-history-grid-container">
                    
                    <div className="mobile-history-gird-section">
                        <MobileHistoryTile fundName={"Defensiv"} data={predDef} />
                    </div>
                    <div className="mobile-history-gird-section">
                        <MobileHistoryTile fundName={"Balanserad"} data={predBal} />
                    </div>
                    <div className="mobile-history-gird-section">
                        <MobileHistoryTile fundName={"Offensiv"} data={predOff} />
                    </div>
                </div>
            </div>
        );
    }else{
        return(
            <div className="history-indexbar-container">
                <IndexBar />
                <div className="history-container">
                    <div className="history-intro-section">
                        <div className="history-intro-section-heading">
                            <p>Tidigare prognoser</p>
                        </div>
                        <div className="history-intro-section-text">
                            <p>De prognostiserade värdena som genereras för dagens förändring sparas nedan för att kunna jämföras med faktiskt utfall. Någon avvikelse är att vänta då processen för NAV-sättning skiljer sig åt från den metod som nyttjas för prognostisering. En alltför stor diskrepans bör dock undersökas närmre.</p>
                            <p>Notera också att en fonds allokering kan påverka precisionen i prognostiseringen. En större andel icke börshandlade instrument kommer att försämra tillförlitligheten.</p>
                        </div>
                    </div>
                    <div className="history-grid">
                        <div className="history-grid-section">
                            <HistoryTile fundName={"Defensiv"} data={predDef} />
                        </div>
                        <div className="history-grid-section">
                            <HistoryTile fundName={"Balanserad"} data={predBal} />
                        </div>
                        <div className="history-grid-section">
                            <HistoryTile fundName={"Offensiv"} data={predOff} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default History;