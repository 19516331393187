import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';

import './MobileIndexBarMenu.css';

import menuIcon from './menu-icon.svg';
import expandIcon from './expand-icon.svg';

import MobileIndexTile from './MobileIndexTile/MobileIndexTile';

const MobileIndexBarMenu = ({ }) => {

    let [isLoaded, setisLoaded] = useState(false)

     // Initiate clock
     let [time, setTime] = useState()
     useEffect(() => {
         const timer = setInterval(() => {
             setTime(new Date().toLocaleString());
         }, 1000);
 
         return () => {
             clearInterval(timer);
         };
     }, []);

    // Get index data
    let [indexData, setIndexData] = useState();

    const getIndexData = async () => {
        console.log("API called /api/indexData/ from MobileIndexBarMenu")
        const data = await axios.get('/api/indexData/', {headers: {'Content-Type' : 'application/json'}, withCredentials: false});
    
        let indexReturn = data.data;
        let parsedData = JSON.parse(indexReturn[0].data);
        
        setIndexData(parsedData);
    }

    let callApi = async () => {
        setisLoaded(false);
        await getIndexData();
        setisLoaded(true);
    }

    // Run function to get data on page load
    useEffect(() => {
        callApi();
    }, [])

    useEffect(() => {
        // check for time, if xx:11, get new data
        if(isLoaded){
            if(time.substr(time.length - 4) === "1:15"){
                console.log("Index data auto update at " + time + " from MobileIndexbarMenu.");
                callApi();
            }
        }
    }, [time])

    

    const logOut = async () => {
        let data = await axios.post('/api/logout/',{refresh_token:localStorage.getItem('refresh_token')} ,{headers: {'Content-Type': 'application/json'}}, {withCredentials: true});
        localStorage.clear();
        axios.defaults.headers.common['Authorization'] = null;
        console.log("Logout successfull. Cleared local storage.")
        console.log(localStorage)

        window.location.href = '/login'
    }
    
    let [isIndexExpand, setIsIndexExpand] = useState(false);
    let [isMenuExpand, setIsMenuExpand] = useState(false);

    let handleIndexbarExpand = () => {
        setIsMenuExpand(false)
        if(isIndexExpand){
            setIsIndexExpand(false)
        }else if(!isIndexExpand){
            setIsIndexExpand(true)
        }
    }

    let handleMenuExpand = () => {
        setIsIndexExpand(false)
        if(isMenuExpand){
            setIsMenuExpand(false)
        }else if(!isMenuExpand){
            setIsMenuExpand(true)
        }
    }


    if(!isLoaded){
        return(
            <div className='mobile-index-menu-container'>
                 <div className='mobile-index-container-collapsed'>
                    {/* 2x MobileinedxTile placeholders */}
                    <div className='mobile-index-tile' key={1}>
                        <div className='mobile-index-item'>
                            <div className='mobile-index-loading-flag shimmer' />
                            <div className='mobile-index-loading-text shimmer' />
                        </div>
                    </div>
                    <div className='mobile-index-tile' key={2}>
                        <div className='mobile-index-item'>
                            <div className='mobile-index-loading-flag shimmer' />
                            <div className='mobile-index-loading-text shimmer' />
                        </div>
                    </div>
                    {/* END 2x MobileinedxTile placeholders */}
                    <div className='mobile-index-tile-icon'>
                        <div className='mobile-index-expand-container'>
                            <div className='mobile-index-expand-icon'>
                                <img src={expandIcon} alt="menu" />
                            </div>
                        </div>            
                    </div>
                </div>
                <div className='mobile-menu-icon-container'>
                    <div className='mobile-menu-icon' onClick={handleMenuExpand}>
                        <img src={menuIcon} alt="menu" />
                    </div>
                </div>
            </div>
        )
    }else{
        return(
            <div className='mobile-index-menu-container'>
                {!isIndexExpand && (
                <div className='mobile-index-container-collapsed'>
                    <MobileIndexTile indexName={indexData.Asset[0]} indexPctChg={indexData["Return today (%)"][0]} indexCurrency={indexData.FX[0]} />
                    <MobileIndexTile indexName={indexData.Asset[1]} indexPctChg={indexData["Return today (%)"][1]} indexCurrency={indexData.FX[1]} />
                    <div className='mobile-index-tile-icon'>
                        <div className='mobile-index-expand-container'>
                            <div className='mobile-index-expand-icon' onClick={handleIndexbarExpand}>
                                <img src={expandIcon} alt="menu" />
                            </div>
                        </div>            
                    </div>
                </div>

                )}
                {isIndexExpand && (
                    <div className='mobile-index-container-expanded'>
                        <div className='mobile-index-tile-icon'>
                            <div className='mobile-index-expand-container'>
                                <div className='mobile-index-expand-icon upside-down' onClick={handleIndexbarExpand}>
                                    <img src={expandIcon} alt="menu" />
                                </div>
                            </div>            
                        </div>
                        <MobileIndexTile indexName={indexData.Asset[0]} indexPctChg={indexData["Return today (%)"][0]} indexCurrency={indexData.FX[0]} />
                        <MobileIndexTile indexName={indexData.Asset[1]} indexPctChg={indexData["Return today (%)"][1]} indexCurrency={indexData.FX[1]} />
                        <MobileIndexTile indexName={indexData.Asset[2]} indexPctChg={indexData["Return today (%)"][2]} indexCurrency={indexData.FX[2]} />
                        <MobileIndexTile indexName={indexData.Asset[3]} indexPctChg={indexData["Return today (%)"][3]} indexCurrency={indexData.FX[3]} />
                        <MobileIndexTile indexName={indexData.Asset[4]} indexPctChg={indexData["Return today (%)"][4]} indexCurrency={indexData.FX[4]} />
                        <MobileIndexTile indexName={indexData.Asset[5]} indexPctChg={indexData["Return today (%)"][5]} indexCurrency={indexData.FX[5]} />
                    </div>
                )}
                
                <div className='mobile-menu-icon-container'>
                    <div className='mobile-menu-icon' onClick={handleMenuExpand}>
                        <img src={menuIcon} alt="menu" />
                    </div>
                </div>
                {isMenuExpand && (
                    <div className='mobile-menu-container'>
                        <div className='mobile-menu-item-large'>
                            <NavLink to="/" className={"white-text"}>
                                <p>Dashboard</p>
                            </NavLink>
                        </div>
                        <div className='mobile-menu-item'>
                            <NavLink to="/defensiv" className={"white-text"}>
                                <p>Defensiv</p>
                            </NavLink>
                        </div>
                        <div className='mobile-menu-item'>
                            <NavLink to="/balanserad" className={"white-text"}>
                                <p>Balanserad</p>
                            </NavLink>
                        </div>
                        <div className='mobile-menu-item'>
                            <NavLink to="/offensiv" className={"white-text"}>
                                <p>Offensiv</p>
                            </NavLink>
                        </div>
                        <div className='mobile-menu-item-large'>
                            <NavLink to="/history" className={"white-text"}>
                                <p>Historik</p>
                            </NavLink>
                        </div>
                        <div className='mobile-menu-item-large' onClick={() => logOut()}>
                            <p>Logga ut</p>
                        </div>
                    </div>
                )}

            </div>
        )
    }
}

export default MobileIndexBarMenu;

//   <img src={expandIcon} alt="menu" />