import React from 'react';
import Highcharts from 'highcharts/highstock'
import PieChart from "highcharts-react-official";

import './AssetAllocation.css'
import HighchartsReact from 'highcharts-react-official';

export default function AsssetAllocation(props) {
    let chartData = props.chartData.data;

    let chartArr = []
    for(let i=0; i < Object.keys(chartData).length; i++){
      let label = Object.values(chartData[i])[0];
      let value = Object.values(chartData[i])[1];

      chartArr.push({name: label, y: value});
    }   

    let chartColors = ['#CCAD90', '#9DBD84', '#84BD9C', '#84AEBD', '#8B84BD', '#BC84BD', '#BD848D', '#E8CFC2', '#DBBEA9', '#CCAD90'] //.reverse()

    let options = {
        chart: {
          type: "pie",
          backgroundColor: 'none',
        },
        accessibility: {
          enabled: false
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            colors: chartColors,
            dataLabels: {
              enabled: false
            },
            borderWidth: 1,
            borderColor: null,
            showInLegend: true
          }
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            //floating: true,
            
            itemStyle: {
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 'bold',
                color: '#dbdbdb'
            },
            itemHoverStyle: {
              color: 'white',
            },

            labelFormatter: function(){
                return this.name + " (" + Number(this.y).toFixed(1) + "%)";
            },

            itemMarginTop: 5,
            itemMarginBottom: 5
        },
        series: [
          {
            name: "Fond - tillgångsallokering",
            data: chartArr,
            color: chartColors,
            innerSize: '60%',
          }
        ]
      };

    return(
        <>
            <div className='asset-allocation-chart-container'>
                <PieChart 
                highcharts={Highcharts}
                options={options}
                containerProps={{ style: { height: '100%', width: '100%'} }}
                />
            </div>
        </>
    )
}