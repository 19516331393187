import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Dash from './pages/Dash.js';
import Fund from './pages/Fund.js';
import LogIn from './pages/LogIn/LogIn.js';
import History from './pages/History/History.js';

function App() {
  // Set title in browser
  //useEffect(() => {
    //document.title = "V. 2 - FondNavigator Dashboard"
  //}, [])

  return (
    <Router  basename="/">
      <Routes>
        <Route exact path='/' element={<Dash />} />
        <Route exact path='/defensiv' element={<Fund fundName="Defensiv" />} />
        <Route exact path='/balanserad' element={<Fund fundName="Balanserad" />} />
        <Route exact path='/offensiv' element={<Fund fundName="Offensiv" />} />
        <Route exact path='/login' element={<LogIn />} />
        <Route exact path='/history' element={<History />} />
      </Routes>
    </Router>
  );
}

export default App;
