import react, { useEffect, useState } from "react";

import './HistoryTile.css';

const HistoryTile = ({fundName, data}) => {

    // Reverse data to get the most recent entry on top when rendering
    data = data.slice().reverse()

    return(
        <div className="history-tile-container">
            <div className="history-tile-header">
                <p>FondNavigator 0-100 {fundName}</p>    
            </div>
            <div className="history-tile-table-container">
                <table className="history-tile-table">
                    <thead>
                        <tr className="history-tile-table-header">
                            <th className="history-table-th align-left">Datum</th>
                            <th className="history-table-th align-right">Prognos</th>
                            {/*<th className="history-table-th align-right">Utfall</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(function(data, i){
                            // Set colorClass
                            let colorClass="history-chg-pos";
                            if(data["pct_chg_prediction"] < 0){
                                colorClass="history-chg-neg";
                            }

                            // Render table row with values and colorClass
                            return(
                                <tr key={i + fundName}>
                                    <td className="history-table-td align-left">{data["time_stamp"]}</td>
                                    <td className={"history-table-td align-right " + colorClass}>{data["pct_chg_prediction"].toFixed(2)}%</td>
                                    {/*<td className="history-table-td align-right history-chg-pos">0.98%</td>*/}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        
    );
};

export default HistoryTile;