import React from "react";
//import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function DashChart (props) {
    const {id, title, data = [[1596461400000, 3],[1617715800000, 4]]} = props;

    var graphLineColor = "#ce8683";
    if(data.data.at(-1) > 0){
        graphLineColor = "#8ed0a2";
    }
    //console.log(id, title, data);

    const options = {
        title: {
            enabled: false,
            text: title,
            style: {
                color: "#ffffff",
                display: "none"
            }
        },
        credits : {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            visible: false,
            crosshair: true
        },
        yAxis: {
            lineWidth: 0,
            visible: false,
            crosshair: true
        },
        series: [{
            name: id,
            data: data.data, //[1, 2, 3]
            color: graphLineColor, //"#ffff", //"rgba(255, 255, 255, 1)",
            //negativeColor: "#ce8683",
            lineWidth: 1
        
        }],
        legend : {
            enabled: false
        },
        tooltip : {
            enabled: false
        },
        chart : {
            backgroundColor: null,
            type: 'area',
            height: 200
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                datagrouping: {
                    enabled: false
                },
                fillColor: {
                    linearGradient: [0, 0, 0, 0], //0, 350, 0, 0], //[0, 900, 1, 50]
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                }
            }
        },
        accessibility: {
            enabled: false
        }
      }

    return(
            <HighchartsReact
            highcharts={Highcharts}
            options={options}
            />
    );
    
}