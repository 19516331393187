import React from 'react'

import './FundStats.css'

import AssetAllocation from '../FundAssetAllocation/AssetAllocation'

export default function FundStats(props) {
    const {statsData} = props;

    // Extract fund change for current day data from statsData
    let fundTodayChgPct = (statsData.return.ret_today_pct).toFixed(2);
    let fundTodayChgSek = (statsData.return.ret_today_sek).toLocaleString('se-SE');

    // Set color class dependant on fund change today
    let todayChangeColorClass = 'chg-pos';
    if(fundTodayChgPct < 0){
        todayChangeColorClass = 'chg-neg'
    }

    // Extract fund AUM for current day data from statsData
    let fundAum = statsData.aum.fund.toLocaleString('se-SE');
    let totalAum = (statsData.aum.all_funds).toLocaleString('se-SE');

    // Extract asset allocation data from statsData
    let fundAssetAllocation = statsData.fundStats.assetType;

    // Extract region allocation data from statsData
    let regionAllocation = statsData.fundStats.regionType;

    // Extract sector llocation data from statsData
    let sectorAllocation = statsData.fundStats.sectorType;
    

    return(
        <div className='fund-stats-grid-container'>
            <div className='div1 fund-stats-tile'>
                <div className='fund-stat-tile-content'>
                    <p className='fund-stats-tile-header'>Dagens förändring</p>
                    <p className={'fund-stats-tile-text ' + todayChangeColorClass}>{fundTodayChgPct}%</p>
                    <p className={'fund-stats-tile-text ' + todayChangeColorClass}>{fundTodayChgSek} SEK</p>
                </div>
            </div>
            <div className='div2 fund-stats-tile'> 
                <div className='fund-stats-asset-allocation-chart-container'>
                    <AssetAllocation chartData = {fundAssetAllocation} />
                </div>
            </div>
            <div className='div3 fund-stats-tile'>
                <AssetAllocation chartData = {sectorAllocation} />
            </div>
            <div className='div4 fund-stats-tile'>
                <div className='fund-stat-tile-content'>
                    <p className='fund-stats-tile-header'>Fondförmögenhet</p>
                    <p className={'fund-stats-tile-text ' + todayChangeColorClass}>{fundAum} SEK</p>
                    <p className='fund-stats-tile-text-small'>av totalt {totalAum} SEK</p>
                </div>
                </div>
            <div className='div5 fund-stats-tile'> 
                <div className='fund-stats-asset-allocation-chart-container'>
                    <AssetAllocation chartData = {regionAllocation} />
                </div>
            </div>
        </div>
    )
}